import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { BlogPost } from '../types';
import { mapEdgesToNodes } from '../utils';
import BlogPostGrid from '../components/BlogPostGrid';
import Breadcrumbs from '../components/Breadcrumbs';

type Props = {
  pageContext: any;
  data: {
    allMdx: {
      edges: { node: BlogPost }[]
    }
  }
}

const AuthorPage = ({ pageContext, data }: Props) => {
  const { name } = pageContext;
  const posts = mapEdgesToNodes(data.allMdx.edges);

  return (
    <Layout pageTitle="Home">
      <SEO title={`Articles by ${name}`} />

      <section>
        <Container>
          <Row className="justify-content-md-center">
            <Col md={10}>
              <Breadcrumbs />
              <h2 className="font-heading fw-bold">Latest articles by {name}</h2>
            </Col>
          </Row>

          <BlogPostGrid posts={posts} />

        </Container>
      </section>
    </Layout >
  );
};

export const query = graphql`
  query($name: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { frontmatter: { author: { name: { eq: $name } } } }) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }
  }
`;

export default AuthorPage;
