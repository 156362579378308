import React from 'react';
import { Link } from 'gatsby';

type BreadcrumbsProps = {
  archiveLabel?: string;
  archiveUrl?: string;
};

const Breadcrumbs = ({
  archiveLabel, archiveUrl,
}: BreadcrumbsProps) => (
  <nav className="breadcrumbs">
    <Link to="/">Home</Link>
    {archiveLabel && <span className="sep">/</span>}
    {archiveLabel && <Link to={archiveUrl}>{archiveLabel}</Link>}
  </nav >
);

export default Breadcrumbs;
